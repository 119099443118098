<template>
    <section>

  
      <b-row>
        <b-col md="4" lg="4" v-for="stat in stats" :key="stat.id">
          <stat
            :variant="stat.variant"
            :icon="stat.icon"
            :name="stat.name"
            :value="stat.value"
            :unit="stat.unit"
          ></stat>
        </b-col>
      </b-row>
    

      <b-row>
        <b-col>
          <bruna-element-loader
            :dataUrl="historicoPrediccionQuebraChart"
            ref="bel0"
            @elementLoaded="elementLoaded"
          ></bruna-element-loader>
        </b-col>
      </b-row>


      <b-row>
        <b-col>
          <ag-grid-table
            ref="optimization-comments-table-3"
            :debug="this.$route.query.debug == '1'"
            :configUrl="historicoPrediccionQuebraConfigUrl"
            :dataUrl="historicoPrediccionQuebraDataUrl"
          ></ag-grid-table>
        </b-col>
      </b-row>
    

    </section>
  </template> 
      
      <script>
  
  import CardTooltip from '@/views/brunacomponents/wrappers/CardTooltipWrapper.vue';
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
  
  import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
  
  import Stat from './components/Stat.vue'
  
  import HeaderCellRenderer from '@/views/brunacomponents/ag-grid-table/cell-renderers/HeaderCellRenderer.vue';
  
  import { AgGridVue } from "ag-grid-vue";
  
  import axios from '@axios'
  import moment from 'moment';
  
  import
  {
    BTabs,
    BTab,
    BOverlay,
    BSpinner,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormTags,
  
    BListGroup,
    BListGroupItem
  } from "bootstrap-vue";
  
  var qs = require('qs');
  import useApiServices from '@/services/useApiServices.js';
  
  
  export default {
  
  
  
    data()
    {

  
      return {
        showOverlay: false,
  
        historicoPrediccionQuebraConfigUrl: useApiServices.historicoPrediccionQuebraConfig,
        historicoPrediccionQuebraDataUrl: useApiServices.historicoPrediccionQuebraData,

        historicoPrediccionQuebraChart: useApiServices.historicoPrediccionQuebraChart,

  
      

  
  
        defaultColDef: {
          headerValueGetter: (header) => { return this.$t(header.column.userProvidedColDef.headerName) },
        },
  
        stats: [],

  

  
  
  
      };
    },
  
  
  
    mounted()
    {
  
  
      this.refreshData();
  
  
    
  
  
  
  
  
    },
  
    created()
    {
  
  
    },
  
    components: {
      BTabs,
      BTab,
      BOverlay,
      BSpinner,
      BrunaElementLoader,
      AgGridTable,
      AgGridVue,
  
  
      BCard,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
  
      Stat,
      BFormTags,
  
  
      BListGroup,
      BListGroupItem,
  
      headerCellRenderer: HeaderCellRenderer,
  
      CardTooltip,
  
  
    },
  
    methods: {
  
  
      refreshData()
      {
  
  
  
        axios.request({
          method: "get",
          url: useApiServices.historicoPrediccionQuebraStats,
          headers: {
            Accept: "application/json",
          },
        })
          .then((response) =>
          {
            this.stats = response.data
          })
  
  
        
  
  
  
      },
  
  
  
  
  
      elementLoaded(event)
      {
        console.log("elementLoaded2", event)
  

  
  
      },
  
  
  
  
    },
  
  };
      </script>
  
  
  
    
    <style lang="scss" scoped>
  @import "~ag-grid-community/dist/styles/ag-grid.css";
  //@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
  @import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
  </style>
      
    <style >
  .btn-padding {
    margin-right: 48px;
  }
  
  .m-estadisticas {
    margin-left: 20px;
    margin-bottom: 1%;
    margin-top: 1%;
  }
  .icon-plan {
    padding-top: 5%;
  }
  
  .ag-row-pinned {
    background-color: #f0e68c !important;
  }
  </style>
      
    
    <style>
  div.ag-watermark {
    display: none !important;
  }
  </style>
    